import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../../components/seo'
import Layout from '../../../components/layout'
import Servizio from '../../../components/Servizio'

import Express12Img from '../../../images/express_12.jpg'

const paginaServizio = {
  titolo: 'Express 12',
  parent: {
    nome: 'Accessori',
    linkUrl: '/servizi/servizi-accessori',
  },
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: Express12Img,
      paragrafi: [
        {
          id: 1,
          testo:
            'Quando vuoi che le tue spedizioni urgenti arrivino a destinazione entro le ore 12.00, affidati a Express12-Service, il servizio time definite di GLS.',
        },
        {
          id: 2,
          testo:
            'Verifica la lista delle località servite e, nel caso in cui la destinazione della tua spedizione non sia presente, rivolgiti alla tua Sede di competenza per trovare una soluzione adatta alle tue esigenze.',
        },
      ],
      pesoDimensioni: [
        {
          id: 1,
          descrizione: 'Peso massimo per collo',
          valore: 'No limit',
        },
        {
          id: 2,
          descrizione: 'Dimesioni massime',
          valore: 'No limit',
        },
      ],
      vantaggi: [
        {
          id: 1,
          testo: 'Ottimizza i tempi di consegna secondo le tue necessità',
        },
        {
          id: 2,
          testo: 'Soddisfatti o rimborsati',
        },
        {
          id: 3,
          testo: 'Disponibile 5 giorni a settimana',
        },
        {
          id: 4,
          testo:
            'Prenotazione del servizio tramite telefono, e-mail, Weblabeling (per maggiori informazioni contatta la tua Sede di riferimento), o eventualmente via fax',
        },
      ],
      compatibileCon: [
        {
          id: 1,
          nome: 'National Express',
          linkUrl: '/servizi/national-express',
        },
        {
          id: 2,
          nome: 'Safe Plus',
          linkUrl: '/servizi/safe-plus',
        },
      ],
      servizi: null,
    },
  ],
}

const Express12 = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default Express12
